import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-msg-error',
  templateUrl: './msg-error.component.html',
  styleUrls: ['./msg-error.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, FeatherModule],
})
export class MsgErrorComponent implements OnInit {
  @Input() messages: any[] = [];
  @Input() control: AbstractControl;
  @Input() showIcon = true;
  errors: any;

  constructor() {}

  ngOnInit(): void {
    this.control?.valueChanges.subscribe(() => {
      if (this.control.errors) {
        this.errors = this.control.errors;
      }
    });
  }

  getParam(value: string) {
    const regex = /\{\{(.*?)\}\}/g;
    const params = value.match(regex);
    if (params) {
      params.forEach((param) => {
        const paramName = param.replace(/\{\{|\}\}/g, '');
        const paramValue = this.errors[paramName]?.requiredLength;
        if (paramValue) {
          value = value.replace(param, paramValue);
        }
      });
    }
    return value;
  }
}
