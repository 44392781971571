<div class="validation-errors ion-margin-start ion-margin-end mt-1">
  <ng-container *ngFor="let item of messages">
    <div
      class="error-message text-danger d-flex align-items-center"
      *ngIf="
        control?.hasError(item.error) && (control.dirty || control.touched)
      "
    >
      @if (showIcon) {
      <i-feather
        name="alert-circle"
        class="error-message__icon me-2 mt-1"
      ></i-feather>
      }
      <span>{{ getParam(item.message) }}</span>
    </div>
  </ng-container>
</div>
